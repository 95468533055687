<template>
  <v-container>
    <v-card>
      <v-card-title class="text-h4 secondary primary--text"> {{ $t('privacyPolicy.title') }}</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  export default defineComponent({
    name: 'PrivacyPolicyView',
  })
</script>

<style></style>
