var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('privacyPolicy.title')))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }